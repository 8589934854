import ArrowForwardIcon from "@material-ui/icons/ArrowForward";

import { ReactComponent as CategoriesIcon } from "../assets/images/categories.svg";
import { ReactComponent as EventsIcon } from "../assets/images/events.svg";
import { ReactComponent as MarketingProgramIcon } from "../assets/images/marketing_program.svg";
import { ReactComponent as MonitoringIcon } from "../assets/images/monitoring.svg";
import { ReactComponent as OptsIcon } from "../assets/images/opts.svg";
import { ReactComponent as SourcesIcon } from "../assets/images/sources.svg";
import { ReactComponent as TasksIcon } from "../assets/images/tasks.svg";
import { ReactComponent as TraitIcon } from "../assets/images/traits.svg";
import { ReactComponent as UsecaseIcon } from "../assets/images/usecase.svg";

import i18n from "../i18n/init";

import pageAccessConfig from "./pageAccessConfig";

const deploymentEnv =
  process.env.NODE_ENV === "test"
    ? "dev"
    : window.env.DEPLOYMENT_ENV || process.env.REACT_APP_DEPLOYMENT_ENV;

const applicationConfig = {
  waitTime: 500,
  paginationConfig: {
    defaultPage: 1,
    defaultPerPage: 50,
  },
  sessionTimeInMinutes: 60,
  endpoints: {
    apiTokenUrl:
      process.env.NODE_ENV === "test"
        ? "https://test.com"
        : window.env.API_TOKEN_URL ||
          process.env.REACT_APP_API_TOKEN_URL ||
          "https://api-development.pg.com/v2/oauth2/token",
    apiBaseUrl:
      process.env.NODE_ENV === "test"
        ? "https://test.com"
        : window.env.API_URL ||
          process.env.REACT_APP_API_URL ||
          "http://localhost:3100",
  },
  basePaths: {
    events: "/events",
    monitoring: "/monitoring",
    marketing_programs: "/marketing-programs",
    sources: "/sources",
    use_cases: "/use-cases",
    traits_responses: "/trait-responses",
    event_property_responses: "/event-property-responses",
    traits: "/traits",
    consent: "/consents",
    insights: "/insights",
    dummy: "/dummy",
  },
  pathnames: {
    opts_revision: "/opts/revision",
    opts_mapping_revision: "/opts/mapping/revision",
    sources_revision: "/sources/revision",
    traits_requests_revision: "/traits/requests/revision",
    marketing_program_map_revision: "/marketing-programs/revision",
    use_cases_manage: "/use-cases/manage",
    events_manage: "/events/manage",
    traits_response_manage: "/trait-responses/manage",
    event_property_response_manage: "/event-property-responses/manage",
    marketing_program_manage: "/marketing-programs/manage",
    use_cases_revision: "/use-cases/revision",
    opts_status_revision: "/opts/status/revision",
    events_map_revision: "/events/map/revision",
    traits_map: "/traits/map",
    traits_responses_revision: "/trait-responses/revision",
    sources_new: "/sources/new",
    sources_manage: "/sources/manage",
    sources_map: "/sources/map",
    opts_mapping_new: "/opts/mapping/new",
    opts_status_new: "/opts/status/new",
    opts_status_manage: "/opts/status/manage",
    opts_new: "/opts/new",
    marketing_programs_new: "/marketing-programs/new",
    marketing_programs_ecosystem_mapping_new:
      "/marketing-programs/ecosystem-mapping/new",
    events_map: "/events/map",
    consent_templates_manage: "/consents/templates/manage",
    consent_manage: "/consents/manage",
  },
  modules: {
    global: "global",
    traits: "traits",
    trait: "Trait",
    service: "Service",
    opts: "opts",
    events: "events",
    data_sources: "data-sources",
    data_source: "Data Source",
    data_sources2: "Data Sources",
    traits_responses: "trait-responses",
    insights: "insights",
    trait_opts: "opt-traits",
    events_responses: "event-responses",
    use_cases: "use-cases",
    dcf_use_cases: "dcf-use-cases",
    marketing_programs: "marketing-programs",
    marketing_programs2: "Marketing Programs",
    new_opt: "New Opt",
    opt_mapping: "Opt Mapping",
    source: "Source",
    segment_source: "Segment Source",
    data_source_disconnect: "Data Source Disconnect",
    marketing_program: "Marketing Program",
    marketing_program_ecosystem: "Marketing Program Ecosystem",
    use_case: "Use Case",
    traits_use_case: "Traits Use Case",
    events_use_case: "Events Use Case",
    event_response: "Event Responses",
    trait_response: "Trait Responses",
    event_mapping: "Event Mapping",
    event_properties: "Event Properties",
    opt_traits: "Opt Traits",
    ecosystem_source: "Ecosystem Source",
    consent_template: "Consent Template",
    consents: "consents",
    templates: "templates",
  },
  status: {
    failed: "failed",
    error: "error",
    info: "info",
    success: "success",
    warning: "warning",
    success_partial: "success-partial",
    deleted: "DELETED",
    new: "NEW",
    existing: "EXISTING",
    updated: "UPDATED",
  },
  inputType: {
    textInput: "text-input",
    dropdown: "dropdown",
    multiSelectDropdown: "multi-select-dropdown",
    custom: "custom",
  },
  common: {
    seconds: "seconds",
    yes: "Yes",
    view_details: "viewDetails",
    status: "status",
    region: "region",
    sourceId: "sourceId",
    trait_name: "traitName",
    trait_description: "traitDescription",
    source_name: "sourceName",
    service_name: "serviceName",
    opt_text_english: "optTextEnglish",
    marketing_program_description: "marketingProgramDescription",
    description: "description",
    marketing_program_name: "marketingProgramName",
    mp_business_name: "mpBusinessName",
    marketing_programs: "marketingPrograms",
    ecosystem_details: "ecosystemDetails",
    ecosystem_source_names: "ecosystemSourceNames",
    mm_dd_yyyy: "MM/DD/YYYY",
    yyyy_mm_dd: "YYYY-MM-DD",
    yyyy_mm_dd_hh_mm_ss: "YYYY-MM-DD hh:mm:ss",
    name: "name",
    label: "label",
    approval: "approval",
    request: "request",
    related_traits: "relatedTraits",
    batch_traits: "batch-traits",
    versions: "versions",
    text_mappings: "textMappings",
    trait_mappings: "traitMappings",
    opt_text: "optText",
    opt_text_mappings: "optTextMappings",
    opt_trait_mappings: "optTraitMappings",
    properties: "properties",
    event_property: "eventProperty",
    job_name: "jobName",
    job_id: "jobId",
    monitoring: "monitoring",
    services: "services",
    global: "Global",
  },
  filters: {
    all: "All",
    date_range: "date-range",
    job_service_type: "job-service-type",
    status: "status",
    regions: "regions",
    region: "region",
    trait_name: "traitName",
    source_name: "sourceName",
    service_name: "serviceName",
    marketing_program_name: "marketingProgramName",
    event_name: "eventName",
    modules: "modules",
    start_date: "startDate",
    end_date: "endDate",
    start_time: "startTime",
    end_time: "endTime",
  },
  requestIdPrefix: "CRS",
  requestStatus: {
    APPROVED: i18n.t("status.approved"),
    REJECTED: i18n.t("status.rejected"),
    PENDING: i18n.t("status.pending"),
    RETURNED: i18n.t("status.returned"),
    PROCESSING: i18n.t("status.processing"),
    SUCCESS: i18n.t("status.success"),
    FAILED: i18n.t("status.failed"),
    SUCCESS_PARTIAL: i18n.t("status.success_partial"),
  },
  regionFilters: [
    {
      name: "GLO",
      label: i18n.t("table_filters.region_labels.glo"),
    },
    {
      name: "AMA",
      label: i18n.t("table_filters.region_labels.ama"),
    },
    {
      name: "APAC",
      label: i18n.t("table_filters.region_labels.asia"),
    },
    {
      name: "EE",
      label: i18n.t("table_filters.region_labels.europe_enterprise"),
    },
    {
      name: "EURO",
      label: i18n.t("table_filters.region_labels.europe_imea"),
    },
    {
      name: "GC",
      label: i18n.t("table_filters.region_labels.greater_china"),
    },
    {
      name: "LA",
      label: i18n.t("table_filters.region_labels.latin_america"),
    },
    {
      name: "NA",
      label: i18n.t("table_filters.region_labels.north_america"),
    },
    {
      name: "WNDR",
      label: i18n.t("table_filters.region_labels.wnd_region"),
    },
  ],
  myApprovalsTooltips: {
    maxVisibleItems: 3,
    toolTipTextPending: i18n.t("traits_requests.tooltip.pending_status"),
    toolTipTextApproved: i18n.t("traits_requests.tooltip.approved_status"),
    toolTipTextReturned: i18n.t("traits_requests.tooltip.returned_status"),
    toolTipTextProcessing: i18n.t("traits_requests.tooltip.processing_status"),
  },
  applicationModules: {
    TRAITS: i18n.t("common.labels.traits"),
    SOURCES: i18n.t("common.labels.sources"),
    OPTS: i18n.t("common.labels.opts"),
    MARKETING_PROGRAMS: i18n.t("common.labels.marketing_programs"),
    EVENTS: i18n.t("common.labels.events"),
    CONSENTS: i18n.t("common.labels.consents"),
  },
  deploymentEnv,
  sidebarMenuConfig: [
    {
      accessRights: pageAccessConfig.searchTraits,
      referenceType: "Consumer",
      text: i18n.t("common.labels.traits"),
      icon: TraitIcon,
      path: "/traits",
      id: "traits",
      children: [
        {
          accessRights: pageAccessConfig.searchTraits,
          text: "Traits Management",
          path: "/traits",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.searchUsecase,
      referenceType: "Consumer",
      text: i18n.t("common.labels.usecase"),
      icon: UsecaseIcon,
      path: "/use-cases",
      id: "use-cases",
      children: [
        {
          accessRights: pageAccessConfig.searchUsecase,
          text: "Use Case Management",
          path: "/use-cases",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.searchSources,
      referenceType: "Consumer",
      text: i18n.t("sidebar_menu_config.sources"),
      icon: SourcesIcon,
      path: "/sources",
      id: "sources",
      children: [
        {
          accessRights: pageAccessConfig.searchSources,
          text: i18n.t("sidebar_menu_config.search_sources"),
          path: "/sources",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.searchOpts,
      referenceType: "Consumer",
      text: i18n.t("common.labels.opts"),
      icon: OptsIcon,
      path: "/opts",
      id: "opts",
      children: [
        {
          accessRights: pageAccessConfig.searchOpts,
          text: i18n.t("sidebar_menu_config.search_opts"),
          path: "/opts",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.searchMarketingPrograms,
      referenceType: "Consumer",
      text: i18n.t("common.labels.mps"),
      icon: MarketingProgramIcon,
      path: "/marketing-programs",
      id: "marketing-programs",
      children: [
        {
          accessRights: pageAccessConfig.searchMarketingPrograms,
          text: i18n.t("sidebar_menu_config.search_mps"),
          path: "/marketing-programs",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.searchEvents,
      referenceType: "Consumer",
      text: i18n.t("common.labels.events"),
      icon: EventsIcon,
      path: "/events",
      id: "events",
      children: [
        {
          accessRights: pageAccessConfig.searchEvents,
          text: i18n.t("events_container.events_management"),
          path: "/events",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.searchConsents,
      referenceType: "Consumer",
      text: i18n.t("common.labels.consents"),
      icon: TraitIcon,
      path: "/consents",
      id: "consent",
      hidden: deploymentEnv === "prd",
      children: [
        {
          accessRights: pageAccessConfig.searchConsents,
          text: i18n.t("consent_container.consent_management"),
          path: "/consents",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.myRequests,
      referenceType: "Consumer",
      text: i18n.t("sidebar_menu_config.my_tasks"),
      icon: TasksIcon,
      path: "/tasks/requests",
      id: "tasks",
      children: [
        {
          accessRights: pageAccessConfig.myRequests,
          text: i18n.t("common.labels.my_requests"),
          path: "/tasks/requests",
          icon: ArrowForwardIcon,
        },
        {
          accessRights: pageAccessConfig.myApprovals,
          text: i18n.t("common.labels.my_approvals"),
          path: "/tasks/approvals",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.searchJobs,
      referenceType: "Consumer",
      text: i18n.t("sidebar_menu_config.monitoring"),
      icon: MonitoringIcon,
      path: "/monitoring/jobs",
      id: "monitoring",
      children: [
        {
          accessRights: pageAccessConfig.searchJobs,
          text: i18n.t("sidebar_menu_config.search_jobs"),
          path: "/monitoring/jobs",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: pageAccessConfig.dummy,
      referenceType: "Testing",
      text: i18n.t("sidebar_menu_config.dummy"),
      icon: MonitoringIcon,
      path: "/dummy",
      id: "dummy",
      children: [
        {
          accessRights: pageAccessConfig.searchJobs,
          text: i18n.t("sidebar_menu_config.dummy"),
          path: "/dummy",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: [
        ...pageAccessConfig.useSwagger,
        ...pageAccessConfig.useGraphql,
      ],
      referenceType: "Consumer",
      text: "Utilities",
      icon: CategoriesIcon,
      path: "/utilities/swagger",
      id: "utilities",
      children: [
        {
          accessRights: pageAccessConfig.useSwagger,
          text: "Swagger",
          path: "/utilities/swagger",
          icon: ArrowForwardIcon,
        },
        {
          accessRights: pageAccessConfig.useGraphql,
          text: "GraphQL",
          path: "/utilities/graphql",
          icon: ArrowForwardIcon,
        },
      ],
    },
    {
      accessRights: [],
      text: i18n.t("sidebar_menu_config.help_me"),
      children: [
        {
          text: i18n.t("sidebar_menu_config.user_guide"),
          path: "/guide",
        },
        {
          text: i18n.t("sidebar_menu_config.contact_us"),
          path: "/ops",
        },
      ],
    },
    {
      accessRights: pageAccessConfig.usePostman,
      referenceType: "Configuration",
      text: "Utilities",
      icon: CategoriesIcon,
      path: "/utilities/postman",
      id: "postman",
      children: [
        {
          accessRights: pageAccessConfig.usePostman,
          text: "Postman",
          path: "/utilities/postman",
          icon: ArrowForwardIcon,
        },
      ],
    },
  ],
  requestsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "name",
      label: i18n.t("common.labels.name"),
    },
  ],
  jobsSearchFilter: [
    {
      name: "jobId",
      label: i18n.t("common.labels.job_id"),
    },
  ],
  approvalsSearchFilter: [
    {
      name: "requestId",
      label: i18n.t("common.labels.request_id"),
    },
    {
      name: "name",
      label: i18n.t("common.labels.name"),
    },
    {
      name: "requester",
      label: i18n.t("common.labels.requester"),
    },
  ],
  referenceTypeInput: [
    { label: "Consumer", value: "Consumer" },
    { label: "Configuration", value: "Configuration" },
  ],
  referenceDataQueries: {
    ecoSystems: `query {
      ecoSystems(includeAll: true) {
        ecoSystemId,
        ecoSystemName,
        description,
        aliasName,
        ecoSystemTraitCharacterLimit,
        ecoSystemTraitDelimiter,
        ecoSystemTraitPrefix,
        ecoSystemTraitSuffix,
        personalDataAllowed
      }
    }`,
    segmentSources: `query {
      ecoSystems(includeAll: true, ecoSystemId: 1) {
        ecoSystemSourceId,
        ecoSystemSourceName
      }
    }`,
    legalEntities: `query {
      legalEntities(includeAll: true) {
        legalEntityId,
        legalOrg,
        legalEntityShortName,
        geoRegionCode,
        geoSubRegionCode
      }
    }`,
    countryCodes: `query {
      legalEntities(includeAll: true,includeNull: false) {
        legalEntityShortName
      }
    }`,
    contactPointTypes: `query {
      contactPoint(includeAll:true){
        contactPointTypeCode
        contactPointTypeName
        contactPointCategoryCode
        contactPointCategoryName
      }
    }`,
    labels: `query Labels {
      labels(refreshCache: true, includeAll: true, labelTypeId: 2) {
          labelId
          labelName
          labelValue
      }
    }`,
    consentTemplateTypesAndCountries: `query {
      legalEntities(includeAll: true, includeNull: false) {
        country
        countryCode
      }
      consentTemplateTypes(includeAll: true) {
        consentTemplateTypeId
        description
      }
    }`,
    consentUseCases: `query {
      consentUseCases (includeAll: true) {
          consentUseCaseId
          consentUseCaseName
          instructions
      }
    }`,
  },
  apigeeHost: {
    local: "api-development.pg.com",
    dev: "api-development.pg.com",
    test: "api-test.pg.com",
    prd: "api.pg.com",
  },
  refreshCacheTimeOut:
    process.env.NODE_ENV === "test"
      ? "5000"
      : window.env.REFRESH_CACHE_TIMEOUT ||
        process.env.REACT_APP_REFRESH_CACHE_TIMEOUT ||
        "300000",
};

export default applicationConfig;
